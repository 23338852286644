<template>
  <div>
    <h4>Fiche Analyse - Reférences</h4>
    <data-table
      :data-source="sortedReferences"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-fiche-parametre"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
          
          
          
    <modal
      title="Ajout d'un parametre fiche analyse"
      id="add-fiche-parametre"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>

        <div class="form-group">
          <label for="add-fiche">Fiche</label>
          <select
            name="add-fiche"
            id="add-fiche"
            class="form-control"
            v-model="fiche"
            v-select="{placeholder:'Selectoinnez la fiche'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in fiches"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div
          class="form-group"
          v-if="hasSection"
        >
          <label for="add-section">Section</label>
          <select
            name="add-section"
            id="add-section"
            class="form-control"
            v-model="section"
            v-select="{placeholder:'Selectoinnez la categorie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in filtredSections"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>


        <div class="form-group">
          <label for="add-parametre">Parametre</label>
          <select
            name="add-parametre"
            id="add-parametre"
            class="form-control"
            v-model="parametre"
            v-select="{placeholder:'Selectoinnez la categorie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in filtredParametres"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-espece">Espèce</label>
          <select
            name="add-espece"
            id="add-espece"
            class="form-control"
            v-model="espece"
            v-select="{placeholder:'Selectoinnez l\' espèce'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in especes"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-libelle">Valeur</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="valeur"
          >
        </div>
          
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
  
    <modal
      title="Modification d'une reference sur la fiche"
      id="update-reference-fiche"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        
        <div class="form-group">
          <label for="update-type">Fiche</label>
          <select
            name="update-type"
            id="update-type"
            class="form-control"
            v-model="fiche"
            v-select="{placeholder:'Selectoinnez le type de maladie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in fiches"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div
          class="form-group"
          v-if="hasSection"
        >
          <label for="update-section">Section</label>
          <select
            name="update-section"
            id="update-section"
            class="form-control"
            v-model="section"
            v-select="{placeholder:'Selectoinnez la section'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in sections"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="update-parametre">Parametre</label>
          <select
            name="update-parametre"
            id="update-parametre"
            class="form-control"
            v-model="parametre"
            v-select="{placeholder:'Selectoinnez la categorie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in filtredParametres"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-espece">Espèce</label>
          <select
            name="update-espece"
            id="update-espece"
            class="form-control"
            v-model="espece"
            v-select="{placeholder:'Selectoinnez l\' espèce'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in especes"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-libelle">Valeur</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="valeur"
          >
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="loading"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../../components/dataTable/local.vue'
  import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
  import Modal from '../../../../components/modal.vue'
  import { ADD_REFERENCE_FICHE, UPDATE_REFERENCE_FICHE } from '../../../../graphql/ficheAnalyse'
  const Parametre = () => import('../../../../components/admin/sanitaire/ficheAnalyse/referenceParametre.vue')
  const Espece = () => import('../../../../components/identification/animal/espece.vue')
  const Actions = () => import('../../../../components/admin/sanitaire/ficheAnalyse/referenceAction.vue')

  export default {
      components: { DataTable, Modal},
      data(){
          return {
              fiche: null,
              valeur: null,
              hasSection: false,
              section: null,
              parametre: null,
              espece: null,
              has_error: false,
              error_msg: null,
              filtredSections: [],
              filtredParametres: []
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.valeur = this.selectedObject.valeur
                      this.parametre = this.selectedObject.parametre
                      this.espece = this.selectedObject.espece
                      let p = this.parametres.find(item => item.uid === this.selectedObject.parametre)
                      if(p){
                        this.fiche = p.fiche
                        this.hasSection = true
                        this.section = p.section
                      }else{
                        this.hasSection = false
                        this.section = null
                      }
                  }
              },
              deep: true
          },
          fiche(){
            
            if(this.fiche !== null) {
              let sections = [...this.sections].filter(item => item.fiche === this.fiche)
              if(sections.length > 0){
                this.hasSection = true
                this.filtredSections = sections
              }
              else{
                this.filtredSections = []
                this.filtredParametres = [...this.parametres].filter(item => item.fiche.split(',').includes(this.fiche))
                this.hasSection = false
              }
            }
            else {
              this.filtredSections = []
              this.filtredParametres = []
            }
          },
          section(){
            if(this.section) this.filtredParametres = [...this.parametres].filter(item => item.section === this.section && item.fiche.split(',').includes(this.fiche))
            else this.filtredParametres = []
          }
          
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT',
              setLoading: 'SET_LOADING'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.setLoading(false)
              this.valeur = null
              this.fiche = null
              this.section = null
              this.hasSection = false
              this.parametre = null
              this.espece = null
              this.has_error = false
              this.error_msg = null
          },
          adding(){
            this.setLoading(true)
              let data = {
                  valeur: this.valeur,
                  espece: this.espece,
                  parametre: this.parametre
              }
              this.$apollo.mutate({
                  mutation: ADD_REFERENCE_FICHE,
                  variables: {
                      "reference": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Reference ${this.libelle} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  this.setLoading(false)
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editing(){
              this.setLoading(true)
              let data = {
                valeur: this.valeur,
                espece: this.espece,
                parametre: this.parametre
              }
              this.$apollo.mutate({
                  mutation: UPDATE_REFERENCE_FICHE,
                  variables: {
                      "reference": {
                          ...data
                      },
                      "uid": this.selectedObject.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Reference ${this.libelle} on ${this.selectedObject.uid} updated successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  this.setLoading(false)
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              selectedObject: 'selectedObject',
              parametres: 'sanitaire/ficheParametres',
              references: 'sanitaire/ficheReferences',
              sections: 'sanitaire/ficheSections',
              fiches: 'sanitaire/ficheAnalyses',
              especes: 'identification/activeEspeces',
              loading: 'loading'
          }),
          sortedReferences (){
            return [...this.references].sort((a,b) => {
              let pa = this.parametres.find(i => i.uid === a.parametre)
              // let fa = this.fiches.find(i=> i.uid === pa.fiche)
              let pb = this.parametres.find(i => i.uid === b.parametre)
              // let fb = this.fiches.find(i=> i.uid === pb.fiche)
              return pa.libelle.localeCompare(pb.libelle)
            })
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Parametre', name: 'parametre', type: COMPONENT_TYPE, component: Parametre },
                  {label: 'Espèce', name: 'espece', type: COMPONENT_TYPE, component: Espece },
                  {label: 'Valeur', name: 'valeur', type: TEXT_TYPE, sortable: true},
                 
                //   {label: 'Fiche', name: 'type', type: COMPONENT_TYPE, component: Fiche},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>